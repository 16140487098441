import './pickle.css';

function PickleInfo(props) {
    return (
        <div className='pickle-div'>
            <h2>{props.name}</h2>
            <div className='pickle-image-holder'>
                <img
                    className='pickle-image'
                    src={process.env.PUBLIC_URL + props.img}
                    alt='' />
            </div>
            <div>
                {props.description}
                <p>{"🌶️".repeat(props.spicy)}</p>
            </div>
        </div>
    );
};

function PickleInfoPage() {
    return (
        <div style={{ color: 'white' }}>
            <PickleInfo 
                name='Classic Half Sour' 
                img='/pickle_images/half_sour.jpg'
                description="Our half sour pickle offers a delightful crunch with a fresh, vibrant flavor that captures the essence of the cucumber. Less tangy than a full sour, it balances a mild saltiness with a crisp, slightly tangy bite, accented by hints of garlic and dill. It's the perfect blend of fresh and brined, providing a refreshing, savory snack that complements any meal." 
            />
            <PickleInfo 
                name='Classic Dill' 
                img='/pickle_images/classic_dill.jpg'
                description="Our dill pickles are meticulously crafted to deliver that iconic briny crunch you crave, making them the perfect addition to sandwiches, burgers, or enjoyed right out of the jar. Each crisp bite bursts with bold, tangy flavor, perfectly balanced by the aromatic notes of fresh dill and garlic. Indulge in the timeless taste with every delicious bite!" 
            />
            <PickleInfo 
                name='Maple Dill' 
                img='/pickle_images/maple-dill.jpg'
                description="Our maple syrup dill pickle offers an intriguing blend of sweet and savory flavors. The initial taste is a delightful burst of rich, natural pure New Hampshire maple syrup sweetness, which is quickly followed by the classic, tangy crunch of a dill pickle. Hints of garlic and the aromatic essence of dill balance the sweetness, creating a unique and harmonious flavor profile. This pickle is a surprising yet delicious treat, perfect for adventurous snackers and adding a twist to traditional dishes."
            />
            <PickleInfo 
                name='Sweet Heat Maple Dill' 
                img='/pickle_images/spicy-maple-dill.jpg'
                spicy={1}
                description="Our spicy maple dill pickle delivers an exciting fusion of flavors that tantalize the taste buds. The initial sweetness of rich New Hampshire maple syrup is quickly followed by the fresh, herbal notes of dill. Just as you savor this blend, a fiery kick of spice emerges, adding a bold heat that lingers pleasantly. With its perfect balance of sweet, savory, and spicy, this pickle offers a dynamic taste experience that's both unique and irresistible, ideal for those who love a bit of adventure in their snacks."
            />
            <PickleInfo 
                name='Bread & Butter' 
                img='/pickle_images/bread-and-butter.jpg'
                description="Our bread & butter pickle offers a delightful balance of sweet and tangy flavors. Crisp cucumber slices are infused with a blend of sugar, vinegar, and spices, creating a mildly sweet yet pleasantly tart taste. Hints of mustard seed, celery seed, and turmeric add a subtle warmth and depth to the flavor profile. With their perfect harmony of sweetness and acidity, bread & butter pickles are a classic, versatile treat that pairs wonderfully with sandwiches, burgers, or enjoyed straight from the jar." 
            />
            <PickleInfo 
                name='Miso' 
                img='/pickle_images/miso.jpg'
                description="Discover the umami-rich delight of our miso-flavored pickles! Infused with the deep, savory essence of miso, these pickles offer a unique twist on the traditional snack. Each bite delivers a perfect balance of salty, tangy, and subtly sweet flavors, enhanced by the complex, fermented notes of miso. Perfect as a standalone treat, a unique addition to charcuterie boards, or a flavorful complement to your favorite dishes, our miso-flavored pickles are sure to elevate your snacking experience to new, delicious heights!" 
            />
            <PickleInfo 
                name='Soy Sauce & Thai Chilis' 
                img='/pickle_images/soy.jpg'
                spicy={2}
                description="Ignite your taste buds with our Soy Sauce and Thai Chili flavored pickles! Marinated in rich soy sauce and spiked with the fiery heat of Bird’s Eye Thai chilies, these pickles offer a bold fusion of umami and spice. Each crunchy bite delivers an exhilarating burst of savory, salty goodness, followed by a tantalizing kick of heat that lingers. Perfect for those who crave adventurous flavors. Warning: The intense heat of these chilis isn't for the faint of heart—dare to take the plunge into a world of bold taste with every bite!" 
            />
            <PickleInfo 
                name='Beer (IPA)' 
                img='/pickle_images/beer-ipa.jpg'
                spicy={3}
                description="Infused with the distinctive hoppy notes of a craft IPA, these pickles offer a refreshing twist on a classic. Each bite delivers a perfect balance of tangy brine and the bold, citrusy bitterness of your favorite IPA, creating a unique and flavorful experience. Ideal for craft beer enthusiasts and adventurous snackers alike. This particular pickle is 21+ only." 
            />
            <PickleInfo 
                name='Bourbon Whiskey' 
                img='/pickle_images/bourbon-whiskey.jpg'
                description="Indulge in a sophisticated snacking experience with our Bourbon Whiskey Pickles! Infused with rich, smooth bourbon whiskey, these pickles deliver a distinctive blend of tangy brine and the warm, caramel notes of fine bourbon. Elevate your snacking game with a touch of bourbon elegance! This particular pickle is 21+ only." 
            />
            <PickleInfo 
                name='Honey Apple Cider' 
                img='/pickle_images/honey-apple-cider.jpg'
                description="Experience the delightful blend of sweet and tangy with our Honey Apple Cider Pickles! Infused with the natural sweetness of honey and the crisp, tangy kick of apple cider vinegar, these pickles offer a refreshing twist on the traditional. Each crunchy bite delivers a harmonious balance of briny goodness, honeyed sweetness, and the vibrant tang of apple cider vinegar." 
            />
            <PickleInfo 
                name='Curry' 
                img='/pickle_images/curry.jpg'
                spicy={3}
                description="Discover a burst of exotic flavor with our Curry Flavored Pickles! Infused with a rich blend of aromatic curry spices, these pickles offer a unique twist on the traditional tangy crunch. Each bite delivers a harmonious fusion of zesty brine and warm, savory curry notes, with hints of turmeric, cumin, and coriander."
            />
            <PickleInfo 
                name='Bloody Mary' 
                img='/pickle_images/bloody-mary.jpg'
                spicy={4}
                description="Infused with the iconic flavors of the classic cocktail, these pickles bring together the tangy zest of ripe tomatoes, the bold kick of horseradish, and the perfect hint of Worcestershire and celery salt. Each crunchy bite captures the essence of a Bloody Mary. Whether you're hosting a brunch or just craving something extraordinary, our Bloody Mary Flavored Pickles are sure to become your new obsession." 
            />
            <PickleInfo 
                name='Gochujang' 
                img='/pickle_images/gochujang.jpg'
                spicy={3}
                description="Discover the bold fusion of flavors in our Gochujang Cucumber Pickles! These crisp, refreshing cucumbers are marinated in a vibrant blend of traditional Korean gochujang, a spicy and savory fermented chili paste. Each bite delivers a perfect balance of heat and tanginess, enhanced by the natural crunch of fresh cucumbers." 
            />
            <PickleInfo 
                name='Vidalia Onion Dill' 
                img='/pickle_images/vidalia.jpg'
                description=" These are not your ordinary dill pickles. Packed with an abundance of sweet Vidalia onions, they offer a deliciously unique twist on a classic favorite. The perfect balance of tangy dill and sweet, caramelized Vidalia onions creates a flavor explosion in every bite." 
            />
            <PickleInfo 
                name='Totally Not a Zucchini' 
                img='/pickle_images/zucchini.jpg'
                description="Unveil a delightful surprise with our Secret Zucchini Bread & Butter Pickles! At first glance, they look like your favorite bread & butter pickles, but take a bite and discover the unique twist of crisp, flavorful homegrown zucchini. Marinated in a sweet and tangy blend of spices, these pickles deliver the classic taste you love with an unexpected and delicious twist." 
            />
            <PickleInfo 
                name="Bird's Eye Thai Chilis" 
                img='/pickle_images/chilis.jpg'
                spicy={5}
                description="Okay, this one is a bit of an outlier. They're not cucumbers and we didn't grow the peppers ourselves. But we got them for a different recipe and we didn't want them to go to waste! These pickled peppers are extremely spicy - not for the faint of heart." 
            />
            <div style={{ clear: 'both' }} />
            <footer>
                <p class="copyright">© 2024</p>
            </footer>
        </div>
    )
}

export default PickleInfoPage;