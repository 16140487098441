import { SingleEliminationBracket, Match, SVGViewer, createTheme } from '@g-loot/react-tournament-brackets';
import matches from './matches.json';
import { useWindowSize } from "@uidotdev/usehooks";

const theme = createTheme({
    textColor: { main: '#000000', highlighted: '#F4F2FE', dark: '#707582' },
    matchBackground: { wonColor: '#2D2D59', lostColor: '#1B1D2D' },
    score: {
        background: {
            wonColor: `#004B49`,
            lostColor: '#10131C',
        },
        text: { highlightedWonColor: '#7BF59D', highlightedLostColor: '#FB7E94' },
    },
    border: {
        color: '#292B43',
        highlightedColor: 'RGBA(152,82,242,0.4)',
    },
    roundHeader: { backgroundColor: '#3B3F73', fontColor: '#F4F2FE' },
    connectorColor: '#3B3F73',
    connectorColorHighlight: 'RGBA(152,82,242,0.4)',
    svgBackground: '#0F121C',
});

function Bracket() {
    const size = useWindowSize();
    const finalWidth = Math.max(size.width);
    const finalHeight = Math.max(size.height);
    return (
        <div>
            <SingleEliminationBracket
                matches={matches}
                matchComponent={Match}
                theme={theme}
                options={{
                    style: {
                        roundHeader: {
                            backgroundColor: theme.roundHeader.backgroundColor,
                            fontColor: theme.roundHeader.fontColor,
                        },
                        connectorColor: theme.connectorColor,
                        connectorColorHighlight: theme.connectorColorHighlight,
                    },
                }}
                svgWrapper={({ children, ...props }) => (
                    <SVGViewer
                        background={theme.svgBackground}
                        SVGBackground={theme.svgBackground}
                        width={finalWidth}
                        height={finalHeight}
                        {...props}
                    >
                        {children}
                    </SVGViewer>
                )}
            />
            <footer>
            <p class="copyright">© 2024</p>
          </footer>
        </div>
    );
};

export default Bracket;