function Homepage() {
    return (
        <div style={{ color: 'white' }}>
            <h1>Scooter's Paw Lickin' Pickles</h1>
            <div style={{ "textAlign": "left", marginLeft: '20px', marginRight: '20px' }}>

                <h3>About the Tournament</h3>
                <p>
                    Welcome to the 1st Annual Scooter's Paw Lickin' Pickles Tournament, where we celebrate the best homemade pickle recipes and crown the ultimate champion! This event is a unique opportunity to taste and vote on a large variety of different pickle flavors, all crafted from cucumbers and other vegetables homegrown right here in Hollis, NH.
                </p>


                <p>
                    <strong>Date:</strong> Saturday, July 27, 2024<br />
                    <strong>Location:</strong> Hollis, NH<br />
                </p>
                <br />

                <h3>Meet Our Host</h3>
                <p>

                    Our charming host for this event is Scooter, an Australian Shepherd mix with a zest for life that matches the zest of our pickles! Scooter is always ready for an adventure and eager to meet everyone, so don't hesitate to give her lots of pets and affection! She's kind of a <i>big dill</i> around here.
                </p>
            </div>
            <img src={process.env.PUBLIC_URL + '/dog_images/scooter-scarf.jpg'} alt='image of dog in pickle scarf' class='roundedcorners' style={{ 'maxWidth': '100%', width: '500px', marginRight: '20px' }} />

            <div style={{ "textAlign": "left", marginLeft: '20px' }}>
                <h3>The Pickles</h3>
                <p>
                    This year's Scooter's Paw Lickin' Pickles Tournament features an impressive array of flavors that cater to every palate, from the traditional to the adventurous. Our 16 homemade varieties range from the familiar comfort of Classic Dill to the bold and innovative notes of Whiskey and Miso. Each pickle is a refrigerator pickle, made fresh this week to ensure optimal crunch and flavor. Our dedicated local artisans have crafted these pickles with care, using homegrown cucumbers and vegetables from Hollis, NH. Whether you prefer the sweet tang of Bread-and-Butter or the fiery kick of Bird's eye Thai chili, there's something here to tantalize every taste bud.
                </p>
            </div>
            <br />
            <figure>
                <img src={process.env.PUBLIC_URL + '/dog_images/cucumbers.jpg'} alt='missing' style={{ 'maxWidth': '100%', width: '700px' }} class='roundedcorners' />
                <figcaption>Scooter in her cucumber garden</figcaption>
            </figure>
            <figure>
                <img src={process.env.PUBLIC_URL + '/dog_images/guarding.jpg'} alt='missing' style={{ 'maxWidth': '100%', width: '700px' }} class='roundedcorners' />
                <figcaption>Scooter guarding her freshly picked cucumbers</figcaption>
            </figure>

            <div style={{ "textAlign": "left", marginLeft: '20px' }}>
                <h3>Voting</h3>
                <p>
                    The voting process for Scooter's Paw Lickin' Pickles Tournament follows a single elimination bracket format with the 16 contestants competing across 4 rounds. At each stage, participants will sample two pickle flavors head-to-head and cast their votes to determine the winner of each match-up. The winning pickle from each round advances to the next stage, narrowing the field of contenders. This process continues until the final round, where the two remaining pickles compete for the title of <i>Most Paw Lickin' Pickle</i>. Voting is a crucial part of each round, ensuring that the tastiest and most popular pickles progress towards the championship.
                </p>
            </div>

            <img src={process.env.PUBLIC_URL + '/dog_images/scooter-plush.jpg'} alt='dog with plush pickle' style={{ 'maxWidth': '100%', width: '500px' }} class='roundedcorners' />
            <br />

            <div>
          <footer>
            <p class="copyright">© 2024</p>
          </footer>
        </div>
        </div>
    )
}

export default Homepage;