import './App.css';
import { useState } from 'react';
import Bracket from './tournament/Bracket'
import Hamburger from 'hamburger-react'
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import PickleInfoPage from './pickle/PickleInfo';
import Homepage from './homepage/Homepage';

function App() {
  const [isOpen, setOpen] = useState(false)
  return (
    <BrowserRouter>
      <div className="App" >
        <div id="menu">
          <div id="menu-div">
            <Hamburger toggled={isOpen} toggle={setOpen} />
          </div>
          {isOpen ? <div id="menu-contents">
            <img src={process.env.PUBLIC_URL + '/scooterlogo.png'} alt='logo' class="shimmer" />
            <h2>Scooter's Paw Lickin' Pickles</h2>
            <ol>
              <li onClick={() => setOpen(false)}><Link to="./">Home</Link></li>
              <li onClick={() => setOpen(false)}><Link to="./our-pickles">Our Pickles</Link></li>
              <li onClick={() => setOpen(false)}><Link to="./tournament">Tournament</Link></li>
            </ol>
          </div> : null}
        </div>

        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/our-pickles" element={<PickleInfoPage />} />
          <Route path="/tournament" element={<Bracket />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
